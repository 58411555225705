<template>
  <div class="template">
    <section class="one">
      <img src="./assets/IMG-2740.png" />
      <div class="text">
        <h1 class="tac">
          Kvalitní dodávka služeb <br />
          a pronájmu montážních plošin
        </h1>
        <div class="row">
          <div class="col-sm-12 tac mt25">
            <router-link class="btn btn-lg btn-red" to="nabidka">
              Více informací
            </router-link>
            <router-link class="btn btn-lg btn-pronajem white" to="pronajem">
              Jít k pronájmu
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="two">
      <div class="row img-row">
        <div class="col-md-6 type-left small-jcc jce">
          <img
            src="./assets/IMG-2752.jpg"
            style="width: 100%; height: auto"
            alt=""
          />
        </div>
        <div class="col-md-6 type-right small-jcc">
          <img
            src="./assets/IMG-2742.jpg"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="col-md-6 type-left small-jcc small-m jce mb0">
          <img
            src="./assets/IMG-2741.jpg"
            style="width: 100%; height: auto"
            alt=""
          />
        </div>
        <div class="col-md-6 type-right small-jcc small-m mb0">
          <img
            src="./assets/IMG-2747.jpg"
            style="width: 100%; height: auto"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="three">
      <div class="black-box">
        <div class="one">
          <div class="svg info">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#ffff"
                stroke="none"
              >
                <path
                  d="M2370 5113 c-379 -36 -661 -116 -980 -278 -378 -193 -717 -497 -965
-865 -104 -156 -232 -419 -294 -605 -49 -150 -89 -321 -113 -490 -17 -118 -17
-512 0 -630 42 -295 120 -553 242 -800 137 -280 272 -468 494 -691 221 -220
412 -357 681 -489 188 -92 309 -137 500 -185 500 -126 1002 -102 1490 71 150
53 408 183 540 271 302 202 573 480 769 788 72 113 188 353 235 486 235 662
194 1372 -115 1993 -124 250 -263 447 -458 648 -214 222 -430 379 -711 518
-296 146 -572 225 -900 255 -102 9 -333 11 -415 3z m545 -342 c634 -107 1171
-457 1523 -995 170 -258 286 -563 339 -891 24 -153 24 -497 0 -650 -108 -668
-474 -1222 -1042 -1580 -243 -153 -537 -261 -850 -312 -154 -24 -497 -24 -650
1 -497 81 -907 288 -1256 636 -345 343 -555 758 -636 1255 -24 153 -24 497 0
650 105 647 447 1183 983 1541 306 205 675 333 1054 368 108 10 418 -4 535
-23z"
                />
                <path
                  d="M2460 4028 c-25 -14 -58 -44 -75 -67 -27 -39 -30 -50 -30 -120 0 -68
3 -82 27 -117 15 -21 44 -50 65 -64 31 -21 48 -25 113 -25 65 0 82 4 113 25
21 14 50 43 65 64 24 35 27 49 27 116 0 67 -3 81 -27 116 -44 62 -90 88 -167
92 -55 3 -74 -1 -111 -20z"
                />
                <path
                  d="M2130 3189 c-90 -37 -129 -153 -77 -229 46 -68 64 -75 213 -78 l134
-4 0 -743 0 -743 -182 -4 c-163 -3 -187 -5 -218 -24 -101 -59 -104 -195 -6
-268 26 -20 45 -21 297 -26 148 -3 390 -3 538 0 252 5 271 6 297 26 98 72 96
204 -3 265 -36 23 -49 24 -220 27 l-183 4 -2 853 c-3 844 -3 854 -24 881 -51
68 -52 69 -307 71 -140 1 -242 -2 -257 -8z"
                />
              </g>
            </svg>
          </div>
          <h1>Informace o naší firmě</h1>
          <label>
            Společnost APZV - pracovní plošiny, je pro Vás dokonalou volbou pro
            plánované i neplánované akce, jelikož náš proškolený tým je časově
            velmi flexibilní. Možnost téměř okamžitých zásahů při haváriích,
            dlouhodobější a pravidelné opravy, výzdoba domů, obcí, měst a vše na
            co si vzpomenete. Do budoucna počítáme i s nonstop provozem.<br />
            Naše společnost má 10ti letou praxi s montážemi veřejných reklam a
            vzhledem k využívaným výškovým pracem byly pořízeny autoplošiny,
            které si nyní dovolujeme nabídnout veřejnosti. I přes to, že
            portfólio montáží a služeb je široké, plošiny nejsou vždy plně
            vytížené.
          </label>
          <h2>Spokojený zákazník je u nás na prvním místě!</h2>
        </div>
        <div class="two">
          <div class="svg info">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#ffff"
                stroke="none"
              >
                <path
                  d="M2476 5105 c-90 -34 -147 -104 -165 -200 -6 -32 -11 -121 -11 -197 0
-77 -4 -147 -9 -157 -6 -10 -27 -25 -48 -34 -21 -9 -87 -37 -147 -62 -78 -34
-116 -45 -134 -40 -14 3 -80 59 -146 124 -67 65 -137 126 -156 136 -19 10 -61
20 -95 23 -107 8 -131 -8 -382 -261 -199 -201 -222 -227 -238 -276 -21 -65
-16 -131 16 -193 12 -25 89 -110 183 -205 148 -148 165 -163 197 -163 38 1 60
14 77 47 26 47 16 62 -142 222 -155 156 -181 192 -170 235 3 13 96 114 208
225 179 178 206 201 234 201 28 0 51 -18 166 -129 111 -108 141 -131 185 -146
73 -24 127 -16 253 38 57 25 124 52 149 62 66 24 118 70 145 129 22 47 24 64
24 206 0 85 5 172 11 192 18 67 31 69 349 66 276 -3 280 -3 302 -26 22 -21 23
-31 28 -216 l5 -193 30 -49 c38 -60 75 -85 213 -142 223 -91 240 -95 327 -65
39 13 76 42 182 145 114 111 137 128 165 128 29 0 55 -22 240 -208 186 -186
208 -211 208 -240 0 -29 -16 -50 -123 -160 -136 -140 -156 -170 -164 -246 -6
-56 0 -76 82 -276 55 -135 79 -170 143 -207 46 -27 51 -28 239 -33 184 -5 194
-6 215 -28 23 -22 23 -26 26 -302 3 -318 1 -331 -66 -349 -20 -6 -107 -11
-192 -11 -142 0 -159 -2 -206 -24 -59 -27 -105 -79 -129 -145 -10 -25 -37 -92
-62 -149 -54 -126 -62 -180 -38 -253 15 -44 38 -74 146 -185 111 -115 129
-138 129 -166 0 -28 -23 -55 -201 -234 -111 -112 -212 -205 -225 -208 -43 -11
-79 15 -235 170 -136 135 -159 154 -188 154 -45 0 -81 -38 -81 -87 0 -36 10
-47 163 -199 93 -93 180 -171 205 -183 62 -32 128 -37 193 -16 49 16 75 39
276 238 253 251 269 275 261 382 -3 34 -13 76 -23 95 -10 19 -71 89 -136 156
-65 66 -121 132 -124 146 -5 18 3 47 23 94 17 38 44 104 62 147 18 42 40 82
50 87 10 6 81 10 158 10 221 0 298 23 361 110 49 68 52 98 49 430 -3 291 -4
307 -25 345 -28 52 -78 100 -128 122 -32 14 -75 18 -215 20 -97 2 -185 9 -197
15 -11 5 -28 27 -36 47 -100 235 -108 257 -102 281 4 14 60 79 124 145 65 66
126 136 136 155 10 19 20 62 23 96 8 109 -16 146 -251 381 -220 219 -267 253
-358 253 -92 0 -133 -23 -269 -156 -70 -68 -137 -125 -152 -129 -19 -5 -48 3
-105 28 -43 20 -104 45 -136 57 -95 36 -95 34 -99 243 -3 146 -7 190 -21 222
-22 50 -70 100 -122 128 -38 21 -53 22 -355 24 -255 2 -322 -1 -354 -12z"
                />
                <path
                  d="M2613 3906 c-203 -39 -439 -160 -557 -286 -54 -58 -60 -97 -21 -135
42 -43 81 -34 160 34 392 340 961 313 1310 -61 143 -153 223 -329 248 -545 28
-252 -61 -522 -235 -719 -32 -36 -59 -73 -59 -82 -4 -63 26 -102 81 -102 32 0
44 8 92 57 141 150 248 372 278 582 20 132 8 345 -24 454 -61 206 -149 357
-287 496 -139 138 -289 225 -496 287 -76 23 -110 27 -253 30 -111 2 -188 -1
-237 -10z"
                />
                <path
                  d="M1434 3391 c-23 -10 -57 -34 -76 -53 -54 -54 -71 -101 -78 -208 -4
-52 -10 -103 -14 -112 -8 -17 -63 -46 -128 -67 -46 -15 -62 -8 -145 69 -97 91
-197 112 -300 64 -21 -10 -104 -83 -186 -163 -171 -168 -197 -209 -197 -308 0
-84 18 -124 90 -199 75 -78 80 -97 45 -186 -33 -85 -56 -98 -172 -98 -91 0
-142 -19 -195 -72 -70 -71 -73 -85 -73 -353 0 -265 1 -272 69 -343 53 -57 99
-75 206 -80 52 -2 103 -9 114 -14 22 -12 76 -127 76 -163 0 -17 -19 -46 -61
-91 -74 -80 -101 -136 -101 -214 0 -98 25 -137 191 -302 168 -167 200 -188
301 -188 84 0 127 20 213 98 45 41 76 62 92 62 36 0 151 -54 163 -76 5 -11 12
-62 14 -114 5 -107 23 -153 80 -206 71 -68 78 -69 343 -69 267 0 282 3 351 72
55 55 67 88 74 200 5 88 9 106 29 126 23 25 118 67 151 67 11 0 50 -28 87 -61
85 -77 137 -101 215 -101 95 0 142 29 304 194 78 79 151 162 163 185 53 106
34 207 -58 305 -69 75 -85 102 -77 134 12 46 58 132 74 140 9 4 60 10 112 14
107 7 154 24 208 78 67 68 72 90 72 347 0 210 -2 234 -21 275 -46 102 -127
150 -254 150 -44 0 -91 6 -105 13 -30 15 -84 119 -84 164 0 25 12 44 56 90 98
103 124 183 93 289 -15 56 -25 68 -183 226 -157 156 -170 167 -226 183 -105
31 -186 5 -289 -93 -46 -45 -65 -56 -91 -56 -45 0 -148 53 -163 84 -7 14 -13
61 -13 105 0 127 -48 208 -150 254 -41 19 -65 21 -275 21 -203 0 -235 -3 -271
-19z m497 -170 c21 -17 24 -30 30 -118 4 -54 14 -116 23 -138 25 -58 79 -109
156 -144 160 -74 245 -64 359 43 74 70 107 84 149 62 43 -23 280 -268 287
-298 9 -34 -6 -60 -72 -130 -108 -115 -117 -213 -33 -377 57 -112 121 -150
273 -160 142 -9 137 0 137 -255 0 -258 4 -252 -165 -258 -139 -6 -208 -58
-265 -199 -59 -145 -45 -233 53 -337 107 -112 104 -122 -77 -301 -123 -120
-137 -131 -171 -131 -31 0 -47 9 -101 59 -118 108 -194 124 -331 72 -105 -41
-152 -76 -187 -141 -24 -44 -29 -67 -33 -144 -2 -50 -9 -101 -15 -112 -18 -38
-50 -44 -248 -44 -185 0 -189 0 -217 24 -26 23 -28 30 -34 129 -5 88 -10 111
-31 148 -37 62 -62 83 -153 126 -70 33 -89 37 -155 38 -90 0 -119 -14 -208
-96 -53 -50 -68 -59 -100 -59 -34 0 -47 11 -179 142 -133 133 -143 145 -143
180 0 32 9 47 59 100 82 89 96 118 96 208 -1 66 -5 85 -38 155 -43 91 -64 116
-126 153 -37 21 -60 26 -148 31 -99 6 -106 8 -129 34 -24 28 -24 32 -24 217 0
198 6 230 44 248 11 6 62 13 112 15 156 7 222 59 285 220 52 137 34 218 -73
333 -50 53 -58 67 -58 99 0 36 9 48 128 167 70 72 140 136 156 145 41 21 69
10 141 -57 77 -71 121 -92 200 -94 47 0 78 7 142 33 142 57 195 128 201 266 4
103 8 120 34 145 20 19 35 20 224 20 183 0 204 -2 225 -19z"
                />
                <path
                  d="M1518 2450 c-471 -116 -714 -650 -496 -1085 103 -203 272 -340 499
-402 97 -27 289 -25 394 5 126 36 281 129 377 226 58 59 122 190 154 314 23
87 25 111 21 227 -5 134 -18 191 -67 300 -88 195 -288 358 -513 416 -97 25
-264 25 -369 -1z m332 -165 c212 -56 386 -232 435 -436 101 -430 -276 -816
-708 -724 -196 41 -366 192 -434 385 -115 328 78 685 417 774 72 19 221 20
290 1z"
                />
              </g>
            </svg>
          </div>
          <h1>Nabízené služby</h1>
          <label class="df jcsb">
            <ul>
              <li>Mytí vysokotlakým čističem</li>
              <li>Specializujeme se na čerpací stanice</li>
              <li>Fasády domů</li>
              <li>Čistění dešťových svodů</li>
              <li>Výstrčí</li>
            </ul>
            <ul>
              <li>Montáže reklam</li>
              <li>Portika</li>
              <li>Atiky</li>
              <li>apod.</li>
            </ul>
          </label>
        </div>
        <div class="three">
          <div class="svg info">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#ffff"
                stroke="none"
              >
                <path
                  d="M65 4151 c-11 -5 -29 -19 -40 -31 -20 -22 -20 -38 -23 -1014 -3 -903
-2 -994 13 -1022 9 -18 28 -37 41 -43 17 -7 139 -11 378 -11 406 0 391 -3 422
93 l16 52 177 -176 c164 -164 285 -266 621 -530 215 -169 474 -361 535 -398
83 -50 243 -102 334 -108 86 -6 185 14 231 47 26 18 34 19 75 9 137 -35 300
39 396 177 27 40 29 41 77 36 137 -13 301 69 367 183 23 39 29 42 91 54 83 15
133 40 195 98 68 64 101 138 105 233 l2 75 74 52 c40 29 78 52 83 52 6 1 19
-27 30 -61 34 -106 20 -102 408 -106 369 -4 396 0 427 51 19 31 20 58 20 1017
0 954 -1 986 -19 1016 -32 52 -62 57 -301 44 -378 -19 -664 -66 -941 -156
-190 -61 -236 -95 -226 -166 l5 -37 -52 20 c-28 12 -78 31 -111 44 -33 12
-174 70 -313 129 -295 125 -402 156 -541 156 -155 0 -280 -43 -437 -151 l-70
-49 -423 0 -423 0 7 64 c5 49 3 70 -9 95 -54 103 -341 188 -813 240 -229 25
-363 33 -388 22z m340 -231 c266 -30 498 -73 603 -112 l53 -20 -6 -46 c-39
-284 -187 -862 -376 -1469 l-11 -33 -229 0 -229 0 0 850 c0 468 3 850 8 850 4
0 88 -9 187 -20z m4505 -1040 l0 -850 -229 0 -229 0 -42 133 c-105 331 -345
989 -456 1252 -35 82 -60 152 -56 156 11 10 187 57 312 83 202 42 447 72 618
75 l82 1 0 -850z m-2167 824 c40 -9 113 -34 162 -54 50 -21 187 -78 305 -127
118 -49 293 -119 388 -154 l172 -64 61 -155 c120 -306 346 -947 338 -954 -2
-2 -46 -31 -97 -65 l-93 -62 -26 33 c-107 131 -311 315 -678 613 -268 218
-380 311 -465 391 -82 76 -108 94 -139 94 -13 0 -80 -22 -148 -50 -68 -27
-134 -50 -146 -50 -39 -1 -98 -28 -115 -52 -33 -48 -118 -131 -158 -154 -66
-39 -139 -49 -215 -30 -35 9 -70 24 -77 34 -34 43 27 212 126 351 93 132 346
357 467 416 112 55 215 67 338 39z m-888 -216 c-260 -319 -332 -576 -204 -731
61 -74 224 -126 356 -113 128 13 236 70 342 179 46 46 74 67 98 72 19 3 70 22
115 41 l81 34 51 -48 c58 -54 186 -161 500 -416 580 -473 728 -645 653 -760
-24 -36 -75 -66 -114 -66 -44 0 -127 74 -436 393 -269 277 -286 290 -350 263
-61 -25 -83 -95 -48 -151 10 -16 153 -167 317 -334 165 -167 298 -309 296
-315 -11 -35 -73 -75 -136 -87 -81 -15 -92 -7 -382 287 -149 150 -284 278
-299 284 -40 15 -92 -4 -116 -44 -24 -39 -24 -73 0 -112 10 -17 128 -142 261
-278 l242 -246 -27 -36 c-53 -70 -122 -96 -182 -68 -24 11 -142 122 -314 296
-270 271 -278 278 -317 278 -76 0 -128 -74 -99 -142 8 -18 114 -136 237 -261
122 -125 220 -230 216 -234 -9 -10 -125 13 -190 37 -76 29 -180 100 -466 320
-437 336 -626 497 -812 691 -95 99 -174 184 -176 189 -2 5 27 113 63 241 73
254 160 593 196 762 l22 107 324 0 324 0 -26 -32z"
                />
              </g>
            </svg>
          </div>
          <h1>Rychlé jednání a přátelský přístup při objednávce</h1>
          <label>
            Nabízíme rychlý přístup a důvěřívé jednání při objednávce plošin.
          </label>
          <div class="row">
            <div class="col-sm-12 tac mt25">
              <router-link class="btn btn-lg btn-pronajem white" to="pronajem">
                Jít k pronájmu
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="four">
      <odesilaciFormular />
    </div>
  </div>
</template>
<script>
import OdesilaciFormular from "./components/odesilaciFormular.vue";

export default {
  name: "App",
  components: {
    OdesilaciFormular,
  },
};
</script>
<style scoped>
section.one {
  /*height: 100vh;
  background-image: url("./assets/IMG-2740.jpg");
  background-position: center;
  background-position-y: -80px;
  background-size: 100% auto;
  background-repeat: no-repeat;*/
  display: flex;
  justify-content: center;
  background-color: #1a1b1d;
  align-items: center;
}
section.one img {
  width: 100%;
}
section.one div.text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
section.one div.text a:first-child {
  margin-right: 25px;
}
section.one div.text a:last-child {
  margin-left: 25px;
  color: #ffff;
  border: 1px solid #ffff;
}
section.one div.text a {
  padding: 15px 25px;
}
section.one div.text h1 {
  font-size: 70px;
  color: #ffff;
  font-weight: bold;
  margin-top: -60px;
}
section.two {
  margin: 30px 150px;
  /*padding-bottom: 15px;*/
}
section.two img {
  max-width: 78%;
  justify-content: center;
}
section.two div.row div {
  margin-bottom: calc(var(--bs-gutter-x) * 1);
}
.template {
  margin: 0px 0px !important;
  margin-bottom: 200px;
  margin-top: 0px !important;
  width: 100%;
}
.template section.three {
  margin-bottom: 40px;
}
.template section.three ul {
  list-style: none;
  padding-left: 0px;
}
.template section.three .black-box .info {
}
.template div.four {
  margin: 0px auto;
  margin-top: 0px;
  width: 60%;
  margin-bottom: 50px;
}
.black-box {
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #ffff;
}
.black-box h1 {
  font-size: 22px;
  text-align: center;
  margin-bottom: 5px;
}
.black-box label {
  font-size: 12px;
}
.black-box h2 {
  font-size: 20px;
  margin-top: 15px;
  text-align: center;
}
.black-box .one {
  background-color: #dc0000;
}
.black-box .two {
  border-right: 1px solid #ffff;
}
.black-box .svg svg {
  height: auto;
  width: 35px;
}
.black-box > div {
  width: 33%;
  padding: 15px 20px;
}
.black-box > div.two {
  width: 33%;
  margin: 15px 0px;
  padding: 0px 20px;
}
/*
.black-box > div:hover {
  width: 40%;
}*/

@media screen and (max-width: 1050px) {
  section.one div.text h1 {
    margin-top: -90px;
    font-size: 60px;
  }
  section.two {
    margin: 30px 0px;
  }
  .black-box label {
    font-size: 10px;
  }
  .template div.four {
    width: 80%;
  }
  .black-box h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.type-left {
  padding-right: 5px;
  padding-bottom: 10px;
}
.type-right {
  padding-left: 5px;
  padding-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .black-box label {
    font-size: 9px;
  }
  .footer .text-box .main-b {
    display: none;
  }
  .footer .text-box > div {
    width: 100%;
  }
}
@media screen and (max-width: 770px) {
  .small-jcc {
    justify-content: center;
    display: flex;
  }
  .small-m {
    margin-bottom: calc(var(--bs-gutter-x) * 1) !important;
  }
  .type-left {
    padding-right: 0px;
    padding-bottom: 10px;
  }
  .type-right {
    padding-left: 0px;
    padding-bottom: 10px;
  }
  section.one div.text h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 600px) {
  section.one div.text h1 {
    margin-top: -50px;
    font-size: 40px;
  }
  section.one div.text a {
    padding: 10px 20px;
  }
  .btn.btn-red {
    font-size: 15px;
  }
  .btn.btn-pronajem {
    font-size: 15px;
  }
  .black-box {
    flex-direction: column;
  }
  .black-box > div {
    width: 100%;
  }
  .black-box > div.two {
    width: 100%;
  }
  .black-box .two {
    border-right: none;
  }
  .black-box label.jcsb {
    justify-content: space-around;
  }
  .black-box label {
    font-size: 11px;
  }
  .black-box .two label {
    border-bottom: 1px solid #ffff;
  }
  section.two {
    margin: 0px 0px !important;
    margin-top: 20px !important;
  }
  .black-box > div.three {
    text-align: center;
  }
  section.one div.text h1 {
    font-size: 30px;
  }
  section.one div.text a {
    padding: 5px 20px;
  }
  section.one div.text a:first-child {
    margin-right: 15px;
  }
  section.one div.text a:last-child {
    margin-left: 15px;
  }
}
@media screen and (max-width: 400px) {
  section.one div.text h1 {
    font-size: 20px;
    margin-top: -20px;
  }
  section.one div.text a:first-child {
    margin-right: 10px;
  }
  section.one div.text a:last-child {
    margin-left: 10px;
  }
}
</style>

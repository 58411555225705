<template>
  <div id="app">
    <link rel="shortcut icon" href="./assets/logo.png" type="image/x-icon" />
    <div class="mobile_navigastion" :class="{ zed_x: showMobilMenu }">
      <ul>
        <li><router-link to="/"> Domů </router-link></li>
        <li>
          <router-link to="nabidka"> Nabídka plošin </router-link>
        </li>
        <li><router-link to="sluzby"> Nabídka služeb </router-link></li>
        <li><router-link to="pronajem"> Pronájem </router-link></li>
        <li><router-link to="cenik"> Ceník </router-link></li>
      </ul>
      <div>
        <a href="https://www.instagram.com/plosinyvilim/"
          ><img class="insta" src="./assets/instagram.png"
        /></a>
      </div>
    </div>
    <div :class="{ open: showMobilMenu }" class="content">
      <div class="top-title">
        <div
          v-if="mobilMenu"
          @click="showMobilMenu = !showMobilMenu"
          class="hamburger-menu"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M95 4146 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26 -8
785 -10 2474 -8 l2437 3 27 21 c53 39 69 71 69 134 0 63 -16 95 -69 134 l-27
21 -2447 2 c-2019 2 -2452 0 -2477 -11z"
              />
              <path
                d="M95 2546 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26 -8
785 -10 2474 -8 l2437 3 27 21 c53 39 69 71 69 134 0 63 -16 95 -69 134 l-27
21 -2447 2 c-2019 2 -2452 0 -2477 -11z"
              />
              <path
                d="M95 946 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26 -8
785 -10 2474 -8 l2437 3 27 21 c53 39 69 71 69 134 0 63 -16 95 -69 134 l-27
21 -2447 2 c-2019 2 -2452 0 -2477 -11z"
              />
            </g>
          </svg>
        </div>
        <div class="top-logo">
          <router-link to="/"><img src="./assets/logo.png" /></router-link>
        </div>
        <div class="top-menu" v-if="!mobilMenu">
          <ul>
            <li><router-link to="/"> Domů </router-link></li>
            <li>
              <router-link to="nabidka"> Nabídka plošin </router-link>
            </li>
            <li><router-link to="sluzby"> Nabídka služeb </router-link></li>
            <li><router-link to="pronajem"> Pronájem </router-link></li>
            <li><router-link to="cenik"> Ceník </router-link></li>
            <li>
              <a href="https://www.instagram.com/plosinyvilim/"
                ><img class="insta" src="./assets/instagram.png"
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <router-view />
      <footer>
        <div class="footer">
          <div class="icon-box">
            <a class="email" href="mailto: zdenek.vilim@gmail.com">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#ffff"
                  stroke="none"
                >
                  <path
                    d="M340 4344 c-30 -8 -59 -19 -65 -25 -11 -11 2161 -2190 2207 -2215 35
-18 121 -18 156 0 46 25 2218 2204 2207 2215 -6 6 -37 17 -70 25 -53 14 -298
16 -2220 15 -1871 0 -2167 -2 -2215 -15z"
                  />
                  <path
                    d="M23 4048 c-17 -50 -18 -123 -18 -1488 0 -1365 1 -1438 18 -1487 10
-29 22 -53 25 -53 4 0 354 347 777 770 l770 770 -770 770 c-423 424 -773 770
-777 770 -3 0 -15 -24 -25 -52z"
                  />
                  <path
                    d="M4295 3330 l-770 -770 770 -770 c423 -423 773 -770 777 -770 3 0 15
24 25 53 17 49 18 122 18 1487 0 1365 -1 1438 -18 1488 -10 28 -22 52 -25 52
-4 0 -354 -346 -777 -770z"
                  />
                  <path
                    d="M1030 1575 c-495 -495 -761 -768 -755 -774 6 -6 37 -17 70 -25 53
-14 299 -16 2215 -16 1916 0 2162 2 2215 16 33 8 64 19 70 25 6 6 -260 279
-757 776 l-767 767 -238 -235 c-218 -216 -245 -239 -317 -275 l-80 -39 -126 0
-126 0 -80 39 c-72 36 -100 60 -315 273 -129 128 -237 233 -240 233 -2 0 -348
-344 -769 -765z"
                  />
                </g>
              </svg>
              <label> zdenek.vilim@gmail.com </label>
            </a>
            <a href="tel: +420 603 818 964" class="tel">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#ffff"
                  stroke="none"
                >
                  <path
                    d="M1165 4771 c-165 -95 -668 -676 -812 -938 -34 -61 -35 -67 -29 -131
22 -214 117 -521 248 -802 145 -309 390 -679 668 -1010 142 -169 475 -502 640
-642 598 -504 1201 -820 1744 -913 49 -8 102 -15 119 -15 57 0 215 102 454
292 308 246 551 486 592 586 34 81 -32 156 -271 315 -347 229 -670 411 -823
462 -71 24 -77 25 -112 10 -49 -20 -80 -51 -218 -210 -62 -71 -133 -149 -157
-173 l-45 -43 -64 16 c-208 54 -490 201 -724 378 -103 79 -277 247 -373 362
-182 217 -354 529 -423 768 l-22 78 54 55 c30 31 104 98 164 149 140 118 190
171 209 217 15 34 14 42 -5 102 -56 182 -465 871 -613 1033 -82 90 -120 101
-201 54z"
                  />
                  <path
                    d="M2720 4640 l0 -160 48 0 c208 0 484 -68 699 -170 367 -175 644 -446
823 -805 112 -225 160 -394 182 -647 l12 -138 158 0 158 0 0 44 c0 77 -19 247
-41 361 -59 309 -224 662 -420 899 -359 434 -839 697 -1387 761 -70 8 -151 15
-179 15 l-53 0 0 -160z"
                  />
                  <path
                    d="M2720 3843 l0 -160 79 -7 c233 -19 436 -116 606 -290 162 -165 252
-361 271 -588 l7 -78 158 0 159 0 0 41 c0 138 -61 383 -131 521 -122 243 -338
462 -574 580 -149 75 -315 121 -484 134 l-91 7 0 -160z"
                  />
                </g>
              </svg>
              <label> +420 603 818 964 </label>
            </a>
          </div>

          <div class="text-box">
            <div class="main-b">
              <ul>
                <li class="mb15">
                  <router-link to="nabidka"> Nabídka plošin </router-link>
                </li>
                <li><router-link to="sluzby"> Nabídka služeb </router-link></li>
              </ul>
              <ul>
                <li class="mb15">
                  <router-link to="pronajem"> Pronájem </router-link>
                </li>
                <li><router-link to="cenik"> Ceník </router-link></li>
              </ul>
            </div>
            <div class="">
              <ul>
                <li>WAP</li>
                <li>Hilti</li>
                <li>Gema s.r.o.</li>
                <li>Isa s.r.o.</li>
                <li>Heineken ČR</li>
              </ul>
              <ul>
                <li>Activum s.r.o.</li>
                <li>Stavorelax s.r.o.</li>
                <li>Iveco ČR</li>
                <li>Local Partners s.r.o.</li>
                <li>ViGo service s.r.o.</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!--<div class="copyright">© 2021 MP VILÍM, Webdesign by WEBNAPOHODU</div>-->
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => {
    return {
      mobilMenu: true,
      showMobilMenu: false,
    };
  },
  watch: {
    $route() {
      this.showMobilMenu = false;
    },
  },
  methods: {
    handleView() {
      this.mobilMenu = window.innerWidth <= 600;
    },
  },
  created() {
    this.handleView();
  },
};
</script>
<style>
.mobile_navigastion ul {
  padding: 0px;
  list-style: none;
}
.mobile_navigastion ul li {
  margin-bottom: 10px;
}
.mobile_navigastion ul li a {
  text-decoration: none;
  color: black;
  width: 100%;
  text-align: center;
  font-weight: 600;
}
.hamburger-menu {
  width: 30px;
  height: 30px;
  margin-left: 25px;
}
.mobile_navigastion {
  background-color: #f2f0f0;
  height: 100vh;
  position: fixed;
  width: 200px;
  padding: 15px 20px;
  box-shadow: 0px 117px 130px 50px #888888;
}
.content {
  position: absolute;
  top: 0px;
  width: 100%;
  background-color: #ffff;
  transition: 1s transform cubic-bezier(0, 0.12, 0.14, 1);
}
.open {
  transform: translateX(200px);
}
.zed_x {
  z-index: 100;
}
img {
  border-radius: 5px;
}
.top-title {
  display: flex;
  height: 80px;
  align-items: center;
  background-color: #f2f0f0;
  z-index: 700;
}
.top-logo img {
  width: 70px;
  height: auto;
}
.top-logo {
  flex-grow: 3;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.top-menu {
  flex-grow: 4;
}
.top-menu ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0;
  padding: 0;
  font-weight: 400;
}
.top-menu ul li {
  cursor: pointer;
  font-weight: 500;
}
.top-menu ul a {
  text-decoration: none;
  color: black;
  width: 100%;
  text-align: center;
}
.insta {
  width: 22px;
  height: 22px;
}
.template {
  margin: 0px auto;
  margin-bottom: 180px;
  margin-top: 20px;
  width: 60%;
  z-index: -1;
}

.footer {
  background-color: #000000;
  padding: 30px 0px;
  display: flex;
  bottom: 0;
  width: 100%;
}
.footer .icon-box {
  display: flex;
  width: 50%;
  justify-content: space-around;
  border-right: 1px solid white;
}
.footer .icon-box > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.footer .icon-box > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}
.footer .icon-box label {
  color: #ffff;
  margin-top: 15px;
  cursor: pointer;
}
.footer .icon-box svg {
  width: 80px;
  height: auto;
}

.footer .text-box {
  display: flex;
  justify-content: space-around;
  width: 50%;
  align-items: center;
}
.footer .text-box a {
  text-decoration: none;
  color: #ffff;
}
.footer .text-box > div {
  display: flex;
  justify-content: space-around;
  width: 50%;
  align-items: center;
  height: 100%;
}
.footer .text-box .main-b {
  border-right: 1px solid white;
}
.footer .text-box ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.footer .text-box ul li {
  color: #ffff;
  list-style: none;
  cursor: pointer;
  padding-bottom: 5px;
}
.copyright {
  position: absolute;
  right: 10px;
  bottom: 0px;
}

.mb0 {
  margin-bottom: 0px !important;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mt0 {
  margin-top: 0px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.tac {
  text-align: center;
}
td.price {
  width: 80px;
  text-align: center;
}
.jce {
  justify-content: end;
  display: flex;
}
.df {
  display: flex;
}
.jcc {
  justify-content: center;
}
.jcsb {
  justify-content: space-between;
}
.btn.btn-red {
  border: 1px solid #e64c4c;
  background-color: #e64c4c;
  color: #ffff;
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 18px;
}
.btn.btn-red:hover {
  border: 1px solid #d42222;
  background-color: #d42222;
  color: #ffff;
}
.btn.btn-pronajem {
  border: 1px solid #444444;
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 18px;
}
.btn.btn-pronajem.white {
  color: #b9b9b9;
  border: 1px solid #b9b9b9;
}
.btn.btn-pronajem.white:hover {
  color: #ffff;
  border: 1px solid #ffff;
  border: 1px solid #ffff;
}
.btn.btn-pronajem:hover {
  border: 1px solid #000;
}
.black-box .btn.btn-pronajem:hover {
  padding: 6px 21px;
  margin-top: -1px;
}
footer {
  position: absolute;
  width: 100%;
}
section > div.row {
  --bs-gutter-x: 0;
}
@media screen and (max-width: 1050px) {
  .template {
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .footer .text-box .main-b {
    display: none;
  }
  .footer .text-box > div {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
  }
  .footer .icon-box {
    width: 100%;
    border-right: none;
  }
  .footer .text-box {
    display: none;
  }
  .top-logo img {
    margin-left: -25px;
  }
  .top-title {
    position: fixed;
    width: 100%;
  }
  .template {
    padding-top: 80px;
    margin-bottom: 80px !important;
  }
  .insta {
    width: 35px;
    height: 35px;
  }
  .mobile_navigastion ul li a {
    font-size: 18px;
  }
}
</style>

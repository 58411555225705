<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Objednávací formulář</h1>
      </div>
    </div>
    <form ref="form" @submit.prevent="sendEmail">
      <div class="row">
        <div class="col-md-5 form-input" v-if="!showMessage">
          <div class="row" v-if="invalidForm">
            <div class="col-md-12 wrong_line">
              <h1>Vyplntě všechny pole!!</h1>
            </div>
          </div>
          <div class="row">
            <label class="col-12"> Jméno </label>
            <div class="col-12">
              <input
                v-model="surname"
                type="text"
                name="first_name"
                placeholder="Vaše jméno"
              />
            </div>
          </div>
          <div class="row">
            <label class="col-12"> Příjmení </label>
            <div class="col-12">
              <input
                v-model="lastName"
                type="text"
                name="last_name"
                placeholder="Vaše příjmení"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="col-12"> Telefon </label>
              <input
                type="text"
                name="tel_number"
                placeholder="Váš telefon"
                v-model="phone"
              />
            </div>
          </div>
          <div class="row">
            <label class="col-12"> Email </label>
            <div class="col-12">
              <input
                v-model="email"
                type="text"
                name="email_name"
                placeholder="Vás email"
              />
            </div>
          </div>
          <div class="row">
            <label class="col-12"> Poptávka </label>
            <div class="col-12">
              <textarea
                rows="5"
                v-model="message"
                name="message"
                placeholder="Dobrý den, měl bych zájem o pronájem vaší plošiny.."
              >
              </textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input
                class="btn btn-mini btn-send"
                type="submit"
                value="Odeslat poptávku"
              />
            </div>
          </div>
        </div>
        <div
          v-if="showMessage"
          v-bind:class="successfulDispatch ? 'right' : 'faild'"
          class="col-md-5"
        >
          <div>
            <div class="row">
              <div class="col-md-12">
                <h1>
                  {{
                    successfulDispatch
                      ? "Email byl uspěšně odeslán!!"
                      : "Něco se pokazilo zkuste to znova."
                  }}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 mt10">
          <div>
            <img
              src="../assets/IMG-2749.png"
              style="width: 100%; height: auto"
              alt=""
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "Odesilaci_formular",
  data() {
    return {
      showMessage: false,
      successfulDispatch: false,
      invalidForm: false,
      surname: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
    };
  },
  methods: {
    sendEmail() {
      if (
        !this.surname ||
        !this.lastName ||
        !this.phone ||
        !this.email ||
        !this.message
      ) {
        this.invalidForm = true;
      } else {
        emailjs
          .sendForm(
            "service_t7s9crs",
            "template_atgwni9",
            this.$refs.form,
            "user_4uM6xBePoNU5mtzQ1SbPe"
          )
          .then(
            () => {
              this.showMessage = true;
              this.successfulDispatch = true;
            },
            () => {
              this.showMessage = true;
              this.successfulDispatch = false;
            }
          );
      }
    },
  },
};
</script>






<style>
.right > div {
  border: 1px solid #00f32e;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 0px;
  background-color: #00f32e1f;
}
.right h1 {
  font-size: 15px;
  color: #00f32e;
  margin-bottom: 0;
  text-align: center;
}
.faild {
  border: 1px solid #ff2020;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 0px;
  background-color: #ffd2d266;
}
.faild h1 {
  font-size: 15px;
  color: #ff2020;
  margin-bottom: 0;
  text-align: center;
}
.wrong_line {
  border: 1px solid #ff2020;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 0px;
  background-color: #ffd2d266;
}
.wrong_line h1 {
  font-size: 15px;
  color: #ff2020;
  margin-bottom: 0;
  text-align: center;
}
.form-input_n {
  display: none;
}

.form-input .row {
  margin-bottom: 5px;
}
.form-input label {
  width: 100%;
  margin-left: 5px;
  font-weight: 500;
  margin-bottom: -3px;
}
.form-input input {
  width: 100%;
  border-radius: 10px;
  padding-left: 5px;
  border: 1px solid #afafaf;
  font-weight: 400;
}
.form-input textarea {
  width: 100%;
  border-radius: 10px;
  padding-left: 5px;
  border: 1px solid #afafaf;
  font-weight: 400;
}
.form-input input:focus-visible {
  border: 1px solid #808080;
  outline: none;
}
.form-input textarea:focus-visible {
  border: 1px solid #808080;
  outline: none;
}
input.btn-send {
  background: #3f48d8;
  color: white;
  border: 1px solid#3f48d8;
}
input.btn-send:hover {
  background: #4c56f6;
  border: 1px solid#4c56f6;
  color: white;
}
</style>

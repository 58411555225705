<template>
  <div class="template">
    <div class="row">
      <div class="col-md-12">
        <h1>Naše nabídka služeb</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <ul>
              <li>Mytí vysokotlakým čističem</li>
              <li>Specializujeme se na čerpací stanice</li>
              <li>Fasády domů</li>
              <li>Čistění dešťových svodů</li>
              <li>Výstrčí</li>
            </ul>
            <ul>
              <li>Montáže reklam</li>
              <li>Portika</li>
              <li>Atiky</li>
              <li>apd.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <img
            src="./assets/IMG-2746.jpg"
            style="width: 100%; height: auto"
            alt=""
          />
        </div>
        <div class="row">
          <div class="col-sm-12 tac mt25">
            <router-link class="btn btn-lg btn-pronajem" to="pronajem">
              Jít k pronájmu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
</style>

<template>
  <div class="template">
    <div><odesilaciFormular /></div>
  </div>
</template>
<script>
import OdesilaciFormular from "./components/odesilaciFormular.vue";

export default {
  name: "App",
  components: {
    OdesilaciFormular,
  },
};
</script>

import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import VueRouter from 'vue-router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';


import Domu from './domu';
import Nabidka from './nabidka.vue';
import Pronajem from './pronajem.vue';
import Cenik from './cenik.vue';
import Sluzby from './sluzby.vue';
/*import Carousel from './carousel.vue';*/


Vue.use(VueRouter);
Vue.config.productionTip = false



const routes = [
  { path: '/', component: Domu },
  { path: '/nabidka', component: Nabidka },
  { path: '/pronajem', component: Pronajem },
  { path: '/cenik', component: Cenik },
  { path: '/sluzby', component: Sluzby },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

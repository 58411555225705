<template>
  <div class="template">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="mb15">Montážní plošina DT22</h1>
      </div>
    </div>
    <div class="row mb20">
      <div class="col-sm-9 text1">
        Pracovní plošina DT22 se vyznačuje bezkonkurenčním výškovým a samozřejmě
        i bočním dosahem. Plošina má snadno odnímatelný pracovní koš, který lze
        nahradit hákem a celý stroj tedy poté používat jako malý jeřáb.
      </div>
    </div>
    <div class="row list">
      <div class="col-sm-6">
        <div class="row">
          <label class="col-6"> Zařazení: </label>
          <label class="col-6"> Autoplošina do 3,5 t </label>
        </div>

        <div class="row">
          <label class="col-6"> Pracovní výška: </label>
          <label class="col-6"> 22 m </label>
        </div>
        <div class="row">
          <label class="col-6"> Maximální boční dosah: </label>
          <label class="col-6"> 9 m </label>
        </div>
        <div class="row">
          <label class="col-6"> Nosnost, rozměry: </label>
          <label class="col-6">
            230 kg + možnost háku na jeřábovaní (260 kg nosnost),
            1400x700x1100(š,h,v). Po dohodě lze koš 1800x700x1100
          </label>
        </div>
        <div class="row">
          <label class="col-6"> Průjezdní šířka: </label>
          <label class="col-6"> 2050 mm </label>
        </div>
        <div class="row">
          <label class="col-6"> Průjezdní výška: </label>
          <label class="col-6"> 2545 mm </label>
        </div>
        <div class="row">
          <label class="col-6"> Délka: </label>
          <label class="col-6"> 6620 mm </label>
        </div>
        <div class="row">
          <label class="col-6"> Natáčení koše: </label>
          <label class="col-6"> 90° + 90° </label>
        </div>
        <div class="row">
          <label class="col-6"> Otoč: </label>
          <label class="col-6"> 1440° </label>
        </div>
        <div class="row">
          <div class="col-sm-12 text1 mt20">
            Možnost připojit do koše elektriku 220V<br />
            Vodu respektivě WAP NILFISK-ALTO WAP MC 4M-160/620 XT -
            PROFESIONÁLNÍ VYSOKOTLAKÝ ČISTIČ + APLIKÁTOR ČISTÍCÍ CHEMIE
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div>
          <img
            src="./assets/IMG-2755.jpg"
            style="width: 100%; height: auto"
            alt=""
          />
        </div>
        <div class="row">
          <div class="col-sm-12 tac mt25">
            <router-link class="btn btn-lg btn-pronajem" to="pronajem">
              Jít k pronájmu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
.text1 {
  font-size: 13px;
}
.list div div label:first-child {
  font-size: 14px;
  font-weight: 700;
}
.list div div label:last-child {
  font-size: 12px;
}
</style>
